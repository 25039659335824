export default [
    {
    // =============================================================================
    // MAIN LAYOUT ROUTES
    // =============================================================================
      path: '/main',
      redirect: '/',
      component: () => import('@/layouts/Main.vue'),
      children: [
        {
          path: '/',
          name: 'dashboard',
          component: () => import('@views/Dashboard.vue'),
          meta: {
            title: 'Dashboard',
            authRequired: true,
          }
        },
        {
          path: '/account',
          name: 'account',
          component: () => import('@views/account/UserEdit.vue'),
          meta: {
            title: 'Account',
            authRequired: true,
          },
        },
        {
          path: '/allocations',
          name: 'allocations',
          component: () => import('@views/allocations/AllocatedItems.vue'),
          meta: {
            title: "Allocations",
            authRequired: true,
          }
        },
        {
          path: '/allocations/:itemId(\\d+)',
          name: 'allocation-show',
          component: () => import('@views/allocations/AllocatedItemShow.vue'),
          meta: {
            title: "View Allocation",
            authRequired: true,
          },
        },
        {
          path: '/patients/create',
          name: 'patient-create',
          component: () => import('@views/patients/PatientCreate.vue'),
          meta: {
            title: "Add Patient",
            authRequired: true,
          },
        },
        {
          path: '/patients/find',
          name: 'patient-find',
          component: () => import('@views/patients/PatientFind.vue'),
          meta: {
            title: "Find Patient",
            authRequired: true,
          },
        },
        {
          path: '/patients',
          name: 'patients',
          component: () => import('@views/patients/PatientList.vue'),
          meta: {
            title: "Hospital Patients",
            authRequired: true,
          }
        },
        {
          path: '/notifications',
          name: 'notifications',
          component: () => import('@views/notifications/NotificationList.vue'),
          meta: {
            title: "Notifications",
            authRequired: true,
          }
        }
      ]
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
        path: '/full',
        component: () => import('@/layouts/Full.vue'),
        children: [
          {
            path: '/login',
            name: 'login',
            component: () => import('@/views/pages/Login.vue'),
            meta: {
              guestRequired: true,
              title: "Login"
            }
          },
          {
            path: '/error-404',
            name: 'error-404',
            component: () => import('@/views/pages/Error404.vue'),
            meta: {
              title: "404 - Page not Found"
            }
          },
          {
            path: '/error-500',
            name: 'error-500',
            component: () => import('@/views/pages/Error500.vue'),
            meta: {
              title: "500 - Server error"
            }
          },
        ]
    },
    // Redirect to 404 page, if no match found
    {
        path: '/:any(.*)',
        redirect: '/error-404'
    },

]