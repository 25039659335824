import env from '../env.js'
import { getUserData } from '@/helpers/utils'

const state = {
    isLoading: false,
    AppActiveUser: getUserData(),
    domain: env.domain,
    localUserKey: env.localUserKey,
    appName: env.appName,
    config:{
      shortDesc: "Medication Management System."
    },
    windowWidth: null,
    countries: [],
    localGovernments: [],
    dashboardData:{
      "allocations":{
        "total": 0,
        "in_progress": 0,
        "dispatched": 0
      },
      "hospital": null,
    },
    doctors:[]
}

export default state
