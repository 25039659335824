
import { createStore } from 'vuex'

import state from "./state"
import getters from "./getters"
import mutations from "./mutations"
import actions from "./actions"

import moduleLayout from "./modules/layout.js"
import moduleNotifications from "./modules/notifications.js"
import moduleMedicine from './modules/medicines.js'
import moduleAllocatedItem from './modules/allocated-items.js'
import moduleHospitalPatient from './modules/hospital-patients.js'

const store = createStore({
    state,
    actions,
    getters,
    mutations,
    modules: {
        layout: moduleLayout,
        notification: moduleNotifications,
        medicine: moduleMedicine,
        allocatedItem: moduleAllocatedItem,
        hospitalPatient: moduleHospitalPatient,
    },
    strict: process.env.NODE_ENV !== 'production',
})

export default store
